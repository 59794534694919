import { ImageStylesNames } from '@mantine/core';

export const Image: Record<ImageStylesNames, string> = {
  root: 'Root element',
  imageWrapper: 'Wraps image and placeholder',
  placeholder: 'Placeholder wrapper',
  figure: 'figure element, wrap image and figcaption',
  image: 'img element',
  caption: 'figcaption element',
};
